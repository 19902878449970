export default {
  allCampaigns: 'All Campaigns',
  liveCampaigns: 'Live Campaigns',
  futureCampaigns: 'Future Campaigns',
  history: 'History',
  form: {
    placeHolderSearchCampaigns: 'Search by Advertiser, Brand, Campaign Name, ...',
    dates: 'Campaign Period',
    save: 'Save',
    editProduct: 'Edit Product',
    product: 'Product',
    description: 'Description (Optional)',
    price: 'Price',
    originalPrice: 'Original Price (Optional)',
    discount: 'Discount',
    image: 'Image',
    ctaText: 'CTA Text',
    ctaURL: 'CTA URL',
    deleteProduct: 'Delete Product',
    titleConfirmDeleteProduct: 'Are you sure you want to delete this product?',
    cancel: 'Cancel',
    delete: 'Delete',
    imageSize: 'Image Size (Pixels)',
    width: 'Width',
    height: 'Height',
    maxProducts: 'Max Active Products',
    addProduct: 'Add Products',
    imagesSizeError: 'Please include image sizes before adding a product.',
    maxProductsError: `You've reached the active product limit.`,
    titleTooltipAddProduct: 'Please save the campaign first to add a product',
    status: 'Status',
    campaignJson: 'Campaign JSON',
    placeholderCampaignName: 'Enter Campaign Name',
    placeholderAdvertiser: 'Input the Advertiser',
    placeholderBrand: 'Input the Brand name',
    placeholderWidth: '00',
    placeholderHeight: '00',
    placeholderMaxProducts: 'Enter a number',
    createCampaign: 'Create Campaign',
    saveChanges: 'Save Changes',
    continue: 'Continue',
    notifyNoProduct: 'There is no registered product for this campaign.',
    addProductNow: '+Add a product now',
    desDeleteProduct: `Are you sure you want to delete this product?
    This action cannot be undone. `,
    addProductMethod: 'Add Product Method',
    addManually: 'Multi-channel retailing product',
    descAddManually: 'Display real-time product details from multiple ecommerce platforms',
    connectECommercePlatform: 'Connect E-commerce Platform',
    desConnectECommercePlatform: 'Import your item from  a supported e-commerce platform.',
    verify: 'Verify',
    titleECommercePlatform:
      'Enter a link to your e-commerce platform (Shopee, Lazada, Amazon, Coupang.)',
    validationECommerceField: 'Please enter a valid URL from our partner platform.',
    connectWithLazada: 'Connect with Lazada',
    connectSellerAccount: 'Connect with Seller account',
    descConnectSellerAccount: 'Automated verify & synchronize with seller account',
    descConnectWithLazada: 'Add multiples products by connecting with your Lazada seller account.',
    addFeed: 'Seamless Product Listing',
    addFeedTitle: 'Product Listing',
    descAddFeed: 'Simply paste URLs to generate product details',
    descNewManually: 'Display real-time product details from multiple ecommerce platforms',
    labelAddFeed: 'Insert URLs to generate product details',
    add: 'Add',
    note: 'Note:',
    descNote:
      'Product images will automatically adjust to fit the pre-selected campaign sizes; option for manual edit is also available.',
    lazadaURL: 'Lazada URL',
    lazadaPrice: 'Price',
    lazadaOriginalPrice: 'Original Price',
    lazadaDiscount: 'Discount',
    shopeeURL: 'Shopee URL',
    shopeePrice: 'Price',
    shopOriginalPrice: 'Original Price',
    shopeeDiscount: 'Discount',
    titleMenu: 'Choose a template to add products',
    refreshRate: 'Refresh Rate',
    marketForDelivery: 'Market for Delivery',
    assetLink: 'Asset Link',
    placeholderAssetLink: 'Enter a Sharepoint Link',
    placeholderMarket: 'Select the Market',
    titleTooltipMaxProducts: 'Only active products are displayed on the campaigns.',
    titleTooltipRefreshRate: 'Choose a time interval to refresh product details',
    shopCode: 'Shop code',
    connectedSuccessfully: 'Connected successfully',
    shopCodeTooltip:
      'Search items matching Shop code. The Shop code is found in each merchant in this example URL: http://www.rakuten.co.jp/[xyz]',
    placeholderShopCode: 'Enter the shop code',
    tooltipRefreshRateClient: 'Please contact Admin to modify changes',
    tooltipMaxProductsClient:
      'Only active products are displayed on the campaigns. Others are disabled.',
    tooltipExchangePlatform: 'Switch between platforms',
    placeholderCampaignJson: 'Auto generated json link here',
    descPreviewProduct: 'Preview shopper end card will display here',
    productLink: 'Product Link',
    ctaTag: 'CTA Tag',
    placeholderCtaTag: 'Enter CTA Tag'
  },
  table: {
    campaignId: 'Campaign ID',
    campaignName: 'Campaign Name',
    advertiser: 'Advertiser',
    brand: 'Brand',
    startDate: 'Start Date',
    endDate: 'End Date',
    numberOfProducts: 'Max Products',
    imageSizes: 'Size',
    name: 'Name',
    description: 'Description',
    price: 'Price',
    status: 'Status',
    active: 'Active',
    inactive: 'Inactive',
    actions: 'Actions',
    products: 'Products',
    newProduct: 'New Product',
    addProduct: 'Add Products',
    developerViewOnly: 'Developer view only!',
    saveCampaignFirst: 'Please save the campaign first!',
    historyCampaignViewOnly: 'Only view history!',
    newCampaign: 'New Campaign',
    continue: 'Continue',
    totalProducts: 'Total Products',
    titleDeleteTooltip: 'Only admin can delete!',
    deleteCampaign: 'Delete Campaign',
    descDeleteCampaign:
      'Are you sure you want to delete this campaign? This action cannot be undone.',
    deletedSuccessfully: 'Deleted successfully'
  },
  details: {}
};
