import './DetailCampaign.less';
import {
  Row,
  Col,
  Input,
  Space,
  DatePicker,
  Button,
  Form,
  InputNumber,
  Typography,
  Select,
  Tooltip,
  Flex
} from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TitleDetailCampaign from './TitleDetailCampaign/TitleDetailCampaign';
import { actionAddCampaign } from '@store/retail/action';
import { useAppDispatch } from '@store/index';
import { useNavigate } from 'react-router-dom';
import { CAMPAIGNS_PATH, EDIT_CAMPAIGN_PATH } from '@constants/routes';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { modalNoti } from '@utils/Notification';
import {ReactComponent as IcTooltip} from '@assets/svg/tooltip.svg'
import {ReactComponent as IcCopy} from '@assets/svg/copy.svg'
import {ReactComponent as IcCalendar} from '@assets/svg/calendar.svg'
import {ReactComponent as IcCaretDown} from '@assets/svg/caretDown.svg'
import { convertTimestamp, parseDate } from '@utils/index';
import { CheckOutlined } from '@ant-design/icons';
const { Text } = Typography;
const validateMessages = {
  required: '${label} is required!'
};
const NewCampaign = () => {
  const dateFormat = "MMM D, YYYY";
  const { t } = useTranslation('retailScreen');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [widthImage, setWidthImage] = useState<number | null>(0);
  const [heightImage, setHeightImage] = useState<number>(0);
  const [maxProducts, setMaxProducts] = useState<number | null>(0);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [loadingSave, setLoadingSave] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [assetLink, setAssetLink] = useState('');
  const [isCopiedAssetLink, setIsCopiedAssetLink] = useState(false);
  const [refreshRate, setRefreshRate] = useState<null | number>(null);

  const validateRequiredFieldWidth = () => {
    if (!widthImage) {
      return Promise.reject(new Error('Width is required!'));
    }
    if(Number(widthImage) > 9999){
      return Promise.reject(new Error('Cannot be more than 9999'));
    }
    if(Number(widthImage) < 1){
      return Promise.reject(new Error('Cannot be less than 1'));
    }
    return Promise.resolve();
  };

  const validateRequiredFieldHeight = () => {
    if (!heightImage) {
      return Promise.reject(new Error('Height is required!'));
    }
    if(Number(heightImage) > 9999){
      return Promise.reject(new Error('Cannot be more than 9999'));
    }
    if(Number(heightImage) < 1){
      return Promise.reject(new Error('Cannot be less than 1'));
    }
    return Promise.resolve();
  };

  const validateFieldMaxProducts = (e: any) => {
    if(!maxProducts){
      return Promise.reject(new Error('Max active products are required!'));
    }
    if(Number(maxProducts) > 99){
      return Promise.reject(new Error('Cannot be more than 99'));
    }
    if(Number(maxProducts) < 1){
      return Promise.reject(new Error('Cannot be less than 1'));
    }
    return Promise.resolve();
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const today = parseDate(convertTimestamp(Date.now()));
    const startDateCheck = parseDate(convertTimestamp(startDate));

    if(today && startDateCheck){
      if(today >= startDateCheck){
        return current && current < dayjs(Date.now()).endOf('day');
      }
    }
    // Can not select days before today and today
    return current && current < startDate.endOf('day');
  };

  const disableStartDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    if(endDate){
      const endDateDisable = endDate?.subtract(1, 'day');
      return current && current > endDateDisable.endOf('day');
    }
    return false
  };

  const handleSaveCampaign =async (values: any) => {
    setLoadingSave(true);
    setDisableSave(true);
    const startDate = values?.startDate.startOf('day');
    const endDate = values?.endDate.startOf('day');
    const body = {
      name: values?.name,
      advertiser: values?.advertiser,
      brand: values?.brand,
      imageSizes: `${widthImage}x${heightImage}`,
      startDate:startDate.valueOf(),
      endDate: endDate.valueOf(),
      maxProducts: maxProducts,
      market: values?.market,
      assetLink: assetLink
    }
    try {
      const campaignId = await dispatch(actionAddCampaign(body))
      modalNoti({
        type: 'success',
        okText: 'Continue',
        title: 'Success',
        content: 'Campaign added successfully.',
        onOk: () => {
          navigate(`${EDIT_CAMPAIGN_PATH}/${campaignId}`)
        }
      })
    } catch {

      modalNoti({
        type: 'warning',
        okText: 'Try Again',
        title: 'Failed',
        content: 'Please try again.',
        onOk:() => {
          setLoadingSave(false);

        }
      });
    }
  };

  const handleCopyAssetLink = () => {
    navigator.clipboard.writeText(assetLink)
    .then(() => {
      setIsCopiedAssetLink(true);
      setTimeout(() => {
        setIsCopiedAssetLink(false);
      }, 2000);
    })
    .catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  const optionMarkets = [
    { label: 'Australia', value: 'Australia' },
    { label: 'China', value: 'China' },
    { label: 'Hong Kong', value: 'Hong Kong' },
    { label: 'India', value: 'India' },
    { label: 'Indonesia', value: 'Indonesia' },
    { label: 'Japan', value: 'Japan' },
    { label: 'Malaysia', value: 'Malaysia' },
    { label: 'New Zealand', value: 'New Zealand' },
    { label: 'Philippines', value: 'Philippines' },
    { label: 'Regional', value: 'Regional' },
    { label: 'Singapore', value: 'Singapore' },
    { label: 'South Korea', value: 'South Korea' },
    { label: 'Taiwan', value: 'Taiwan' },
    { label: 'Thailand', value: 'Thailand' },
    { label: 'Vietnam', value: 'Vietnam' },
  ]
  return (
    <>
      <TitleDetailCampaign text={`New Campaign`} />
      <Row
        justify="center"
        style={{
          marginTop: '30px',
          paddingBottom:'30px',

        }}
      >
        <Col span={20} style={{
        maxWidth:'1320px'
      }}>
          <Form
            name="campaign"
            layout="vertical"
            onFinish={handleSaveCampaign}
            validateMessages={validateMessages}
            form={form}
          >
            <Row gutter={48}>
              <Col span={12}>
                <Form.Item
                  className="no-asterisk asterisk-after"
                  name="name"
                  label={t('table.campaignName')}
                  rules={[{ required: true }]}
                >
                  <Input
                    type="text"
                    placeholder={t('form.placeholderCampaignName')}
                    className="details__custom-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Row gutter={24}>
                  <Col span={12} className='details__date_split'>
                    <Form.Item
                      className="no-asterisk asterisk-after"
                      name="startDate"
                      label={t('form.dates')}
                      rules={[
                        {
                          validator: async (_, value) => {
                            if (!value) {
                              return Promise.reject(new Error('Start Date is required!'));
                            }
                            return Promise.resolve();
                          },
                        }
                      ]}
                    >
                      <DatePicker
                        inputReadOnly
                        suffixIcon={<IcCalendar/>}
                        style={{
                          width: '100%'
                        }}
                        className="input-field-custom"
                        placeholder={t('table.startDate')}
                        format={dateFormat}
                        onChange={(e) => {

                          setStartDate(dayjs(e?.format('MMM D, YYYY')))
                        }}
                        disabledDate={disableStartDate}

                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="no-asterisk"
                      name="endDate"
                      label={' '}
                      rules={[
                        {
                          validator: async (_, value) => {
                            if (!value) {
                              return Promise.reject(new Error('End Date is required!'));
                            }
                            return Promise.resolve();
                          },
                        }
                      ]}
                    >
                      <DatePicker

                        inputReadOnly
                        suffixIcon={<IcCalendar/>}
                        style={{
                          width: '100%'
                        }}
                        className="input-field-custom"
                        placeholder={t('table.endDate')}
                        format={dateFormat}
                        onChange={(e) => {

                          setEndDate(dayjs(e?.format('MMM D, YYYY')))
                        }}
                        disabledDate={disabledDate}

                      />
                    </Form.Item>
                  </Col>

                </Row>
              </Col>
            </Row>
            <Row gutter={48}>
              <Col span={12} >
                <Form.Item
                  className="no-asterisk"
                  name="market"
                  label={t('form.marketForDelivery')}
                >
                  <Select
                    style={{
                      padding: 0
                    }}
                    className='select-field-custom'
                    showSearch

                    placeholder={t('form.placeholderMarket')}
                    optionFilterProp="label"
                    onSearch={() => {}}
                    options={optionMarkets}
                  />
                </Form.Item>
              </Col>
              <Col span={12} >
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                    className="no-asterisk asterisk-after"
                    name="advertiser"
                    label={t('table.advertiser')}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={t('form.placeholderAdvertiser')} type="text" className="details__custom-input"  />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="no-asterisk asterisk-after"
                      name="brand"
                      label={t('table.brand')}
                      rules={[{ required: true }]}
                    >
                      <Input placeholder={t('form.placeholderBrand')} type="text" className="details__custom-input" />
                    </Form.Item>
                </Col>
                </Row>

              </Col>
            </Row>
            <Row gutter={48}>
            <Col span={12} className='details__padding_right'>

            <Form.Item
                name="campaignJson"
                label={t('form.campaignJson')}
                >
                <Input
                  placeholder={t('form.placeholderCampaignJson')}
                  disabled
                  type="text"
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    height: '48px'
                  }}
                  className="details__custom-input"
                  suffix={<IcCopy className='details__custom-icon' />}
                  />
              </Form.Item>

            </Col>
            <Col span={12} className='details__padding_left'>
              <Row>
                <Col span={24}>
                  <Row>
                    <Text className='details__text__img__size asterisk'>{t('form.imageSize')}</Text>
                  </Row>
                  <Row gutter={16} style={{
                    marginTop: '10px'
                  }}>
                    <Col span={12}>
                      <div className='details__custom-number-field' style={{ display: 'flex', flexDirection: 'row'}}>
                        <Text className='details__text__size'> {t('form.width')}</Text>
                          <Form.Item

                          className="no-asterisk"
                          name="width"
                          rules={[{ validator: validateRequiredFieldWidth }]}
                        >

                          <InputNumber

                            className="input-field-custom details__input__number"

                            onChange={(value: any) => {
                              setWidthImage(value);
                              setDisableSave(false)
                            }}
                            placeholder={t('form.placeholderWidth')}

                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className='details__custom-number-field' style={{ display: 'flex', flexDirection: 'row' }}>
                        <Text className='details__text__size' style={{
                          marginLeft: '10px'
                        }}> {t('form.height')} </Text>

                      <Form.Item
                        className="no-asterisk"
                        name="height"
                        rules={[{ validator: validateRequiredFieldHeight }]}
                      >
                        <InputNumber

                          className="input-field-custom"

                          onChange={(value: any) => {

                              setHeightImage(value);
                              setDisableSave(false);

                          }}
                          placeholder={t('form.placeholderHeight')}
                        />
                      </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Col>

              </Row>
            </Col>

          </Row>
          <Row gutter={48}>
            <Col span={12}>

            <Form.Item
              name="assetLink"
              label={t('form.assetLink')}
              >
              <Input
                type="text"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: '48px'
                }}
                onChange={(e) => {

                  setAssetLink(e.target.value)
                }}
                placeholder={t('form.placeholderAssetLink')}
                className="details__custom-input"
                suffix={isCopiedAssetLink ? <CheckOutlined/> :<IcCopy className='details__custom-icon' onClick={handleCopyAssetLink}/>}
                />
              </Form.Item>

            </Col>
            <Col span={12}>
            <Row gutter={24}>
                 <Col span={12}>
                 <Form.Item
                   name="refreshRate"
                   label={<Flex gap={6} align='center'>
                     <span className='asterisk'>
                     {t('form.refreshRate')}
                     </span>
                     <Tooltip title={t('form.titleTooltipRefreshRate')}>
                       <IcTooltip/>
                     </Tooltip >
                   </Flex>}
                   >
                   <Select
                    suffixIcon={<IcCaretDown/>}
                     style={{
                       padding: 0
                     }}
                     className='select-field-custom'
                     defaultValue={null}
                     showSearch

                     optionFilterProp="label"
                     onChange={(val) => {
                       setRefreshRate(val)

                     }}
                     onSearch={() => {}}
                     options={[
                       {
                         label: 'every 5 minutes',
                         value: 5,
                       },
                       {
                         label: 'every 15 minutes',
                         value: 15,
                       },
                       {
                         label: 'every 30 minutes',
                         value: 30,
                       },
                       {
                         label: 'every 1 hour',
                         value: 60,
                       },
                       {
                         label: 'every 2 hours',
                         value: 120,
                       },
                       {
                         label: 'every 4 hours',
                         value: 240,
                       },
                       {
                         label: 'every 12 hours',
                         value: 720,
                       },

                       {
                         label: 'every day',
                         value: 1440,
                       },
                       {
                         label: 'None',
                         value: null,
                       }
                     ]}
                   />
                 </Form.Item>

               </Col>
               <Col span={12}>
               <Form.Item
                   name="maxProducts"
                   label={<Flex gap={6} align='center'>
                     <span className='asterisk'>
                     {t('form.maxProducts')}
                     </span>
                     <Tooltip title={t('form.titleTooltipMaxProducts')}>
                       <IcTooltip/>
                     </Tooltip >
                   </Flex>}
                   rules={[{ validator: validateFieldMaxProducts }]}
                   className='form-item-max-products max-active-products-custom details__custom-number-field'
                   >
                   <InputNumber
                     className="input-field-custom input-max-width"
                     placeholder={t('form.placeholderMaxProducts')}
                     onChange={value => {
                       setMaxProducts(Number(value));

                     }}
                   />
                 </Form.Item>
               </Col>
              </Row>


            </Col>

          </Row>

            <Row>
              <Space size='large'>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="details__btn__submit"
                  size="middle"
                  loading={loadingSave}
                  disabled={disableSave}
                >
                  {t('form.createCampaign')}
                </Button>
                <Button
                  type="primary"
                  ghost
                  className="details__btn__submit"
                  onClick={() => {
                    navigate(CAMPAIGNS_PATH)
                  }}
                >
                  {t('form.cancel')}
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default NewCampaign;
