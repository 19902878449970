import { EnhancedStore } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState, Store } from "@store/index";
import jwtDecode from "jwt-decode";
import { getAuthUser, sortRoles } from "@utils/index";
import { modalNoti, notiConfig } from "@utils/Notification";
import { LOGIN_PATH } from "@constants/routes";

let store: Store;

export const BASE_URL = process.env.REACT_APP_BASE_URL_AUTH;

export const injectStore = (_store: EnhancedStore<RootState>) =>
(store = _store);

export const baseApi = (baseUrl: string, timeout?: number) => {

  const baseClient = axios.create({
    baseURL: baseUrl ? baseUrl : BASE_URL,
    withCredentials: true,
    timeout
  });

  baseClient.interceptors.response.use((response) => {
     return response;
  },async (error) => {
      console.log('err:::', error)
      if( error?.response?.data?.code === 401) {
        const user = getAuthUser();
        const payload: any = jwtDecode(user?.token);
        try {
          const {data} = await baseClient
          .post(BASE_URL+"/auth/refresh-token", {
            token: user.refreshToken,
            userName: payload.username
          })
          const decode: any = jwtDecode(data?.data?.token);
          localStorage.setItem('authUser', JSON.stringify({
            token: data.data.token,
            email: user.email,
            role: [sortRoles(decode["cognito:groups"])[0]],
            api: user?.api,
            refreshToken: user.refreshToken
          }))
          return baseClient(error.config);
        } catch (err) {
          localStorage.setItem('authUser', JSON.stringify(null))
          notiConfig({
            type:'warning',
            message:'LOGIN EXPIRED',
            description:'Please login agaign!'
          })
          window.location.href=LOGIN_PATH
        }
      }

      if(!error?.response){
        if(error?.config?.url === '/user/product-info-e-commerce'){
          return Promise.reject(error)
        }
        if(error?.config?.url === '/auto/scrape/feed'){
          return Promise.reject(error)
        }
        if(error?.config?.url === '/auto/scrape/lazada'){
          return Promise.reject(error)
        }
        if(error?.config?.url.includes('/auto/scrape/manually')){
          return Promise.reject(error)
        }

        modalNoti({
          type: 'warning',
          okText: 'Try Again',
          title: 'Network Error',
          content: 'Please try again.',
          onOk:() => {
            window.location.reload();
            return Promise.reject(error)
          }
        });
      }

      return Promise.reject(error)

  });

  baseClient.interceptors.request.use((config: any) => {
    const user = getAuthUser();
    config.headers.Authorization = "Bearer "+user?.token;
    return config;
  })

  return baseClient;
}
