import { CAMPAIGNS_PATH } from "@constants/routes";
import { getAuthUser, sortRoles } from "@utils/index";
import jwtDecode from "jwt-decode";
import { FC, ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface Props {
  children?: ReactElement;
}

const AdminRoute: FC<Props> = () => {
  const permissionAllow = ['admin', 'developer', 'admin_testing']
  const user = getAuthUser();
  const decode: any = jwtDecode(user?.token);
  const roles = sortRoles(decode["cognito:groups"])
  if(permissionAllow.includes(roles[0])){
    return  <Outlet />
  }

  return <Navigate to={CAMPAIGNS_PATH}/>
};

export default AdminRoute;