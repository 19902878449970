import React from 'react';

interface Props {
  size?: number;
  height?: number;
  className?: string;
  style?: any;
}

const CaretRight = ({ size = 24, height, className, style }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height ? height : size}
    width={(size * 595.3) / 841.9}
    viewBox="0 0 24 24"
    className={className}
    style={style}
    fill="none"
    data-icon="caret-down"
  >
    <path
      d="M9 4.5L16.5 12L9 19.5"
      stroke="#061C3D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CaretRight;
