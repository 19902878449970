
export const convertTimestamp = (timestamp: number) => {
  // Create a Date object from the timestamp
  const date = new Date(timestamp);
  // Define options for formatting the date
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
  // Convert the Date object to the desired format
  const formattedDate: string = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

export function parseDate(dateString: string): Date | null {
  const months: { [key: string]: number } = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11
  };

  const parts = dateString.split(' ');
  if (parts.length !== 3) {
    return null; // Invalid date format
  }

  const monthIndex = months[parts[0]];
  if (monthIndex === undefined) {
    return null; // Invalid month
  }

  const day = parseInt(parts[1]);
  const year = parseInt(parts[2]);

  if (isNaN(day) || isNaN(year)) {
    return null; // Invalid day or year
  }

  return new Date(year, monthIndex, day);
}

export const getAuthUser = () => {
  const authUser = localStorage.getItem('authUser');
  if (!authUser) {
    return '';
  }
  return JSON.parse(authUser);
};

export const randomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const sortRoles = (roles: any) => {
  const roleScores: any = {
    admin: 100,
    admin_testing: 100,
    developer: 80,
    client_testing: 90,
    client_lego: 50,
    client_disney: 40
  };
  return roles.sort((a: any, b: any) => roleScores[b] - roleScores[a]);
};

export const checkRoleDev = (role: string): boolean => {
  if (role === 'developer') return true;
  return false;
};

export const permissionPickServer = process.env.REACT_APP_ENV === 'development' ? [] : ['admin']

export const fetchImageAsFile = async (url: string, name = 'image.jpg')  => {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], name, { type: blob.type });
  return file;
}

export const truncateString = (str: string, num: number, useEllipsis = true) => {
  if(str){
    const ellipsis = useEllipsis ? '...' : '';
    const maxLen = useEllipsis ? num - ellipsis?.length : num;

    if (str?.length <= num) {
      return str;
    }
    return str.slice(0, maxLen) + ellipsis;

  }else{
    return str;
  }
}

export const platformConfig = (platform: string): any => {
  const platforms = new Map([
    ['lazada', {
      redirectUri: encodeURIComponent(process.env.REACT_APP_LAZADA_CALLBACK || ''),
      clientId: process.env.REACT_APP_LAZADA_CLIENT_ID,
  }]
  ])


  return platforms.get(platform)
}

export const getLocalItem = (key: string, alternative: unknown) =>
  JSON.parse(localStorage.getItem(key) as string) ?? alternative

export const setLocalItem = (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value))

export const splitUrls = (str: string) => {
  // This regex finds all occurrences of http or https that are not at the start of the string
  const regex = /(?<!^)(https?:\/\/)/g;

  // Replace found occurrences with a special delimiter (e.g., "|||https://")
  // "|||" is chosen as an unlikely sequence to appear in a normal URL, making it a good delimiter
  const delimitedStr = str.replace(regex, "|||$1");

  // Split the string by the delimiter to get an array of URLs
  const urls = delimitedStr.split("|||");

  return urls.map(ele => ele?.trim());
}

export const formattedPriceThroughDomain = (price: number, url: string) => {
  const domain: any = new URL(url).origin;
  const countryCode = domain.match(/\.([a-z]+)$/)[1];

  const currencyMap: { [key: string]: string } = {
    us: 'USD', // United States Dollar
    gb: 'GBP', // British Pound Sterling
    eu: 'EUR', // Euro
    ca: 'CAD', // Canadian Dollar
    au: 'AUD', // Australian Dollar
    nz: 'NZD', // New Zealand Dollar
    jp: 'JPY', // Japanese Yen
    cn: 'CNY', // Chinese Yuan
    vn: 'VND', // Vietnamese Dong
    th: 'THB', // Thai Baht
    my: 'MYR', // Malaysian Ringgit
    sg: 'SGD', // Singapore Dollar
    ph: 'PHP', // Philippine Peso
    id: 'IDR', // Indonesian Rupiah
    kr: 'KRW', // South Korean Won
    in: 'INR', // Indian Rupee
    br: 'BRL', // Brazilian Real
    mx: 'MXN', // Mexican Peso
    za: 'ZAR', // South African Rand
    ru: 'RUB', // Russian Ruble
    tr: 'TRY', // Turkish Lira
    sa: 'SAR', // Saudi Riyal
    ae: 'AED', // United Arab Emirates Dirham
    se: 'SEK', // Swedish Krona
    no: 'NOK', // Norwegian Krone
    dk: 'DKK', // Danish Krone
    ch: 'CHF', // Swiss Franc
  };


  const currencyCode = currencyMap[countryCode];

  return new Intl.NumberFormat('en-US', {
     style: 'currency',
     currency: currencyCode ? currencyCode : 'USD',
   }).format(price);
 }

 export const parseFormattedPrice = (formattedPrice: string, url: string) => {
  const domain: any = new URL(url).origin;
  const countryCode = domain.match(/\.([a-z]+)$/)[1];

  const currencyMap: { [key: string]: string } = {
    us: 'USD', // United States Dollar
    gb: 'GBP', // British Pound Sterling
    eu: 'EUR', // Euro
    ca: 'CAD', // Canadian Dollar
    au: 'AUD', // Australian Dollar
    nz: 'NZD', // New Zealand Dollar
    jp: 'JPY', // Japanese Yen
    cn: 'CNY', // Chinese Yuan
    vn: 'VND', // Vietnamese Dong
    th: 'THB', // Thai Baht
    my: 'MYR', // Malaysian Ringgit
    sg: 'SGD', // Singapore Dollar
    ph: 'PHP', // Philippine Peso
    id: 'IDR', // Indonesian Rupiah
    kr: 'KRW', // South Korean Won
    in: 'INR', // Indian Rupee
    br: 'BRL', // Brazilian Real
    mx: 'MXN', // Mexican Peso
    za: 'ZAR', // South African Rand
    ru: 'RUB', // Russian Ruble
    tr: 'TRY', // Turkish Lira
    sa: 'SAR', // Saudi Riyal
    ae: 'AED', // United Arab Emirates Dirham
    se: 'SEK', // Swedish Krona
    no: 'NOK', // Norwegian Krone
    dk: 'DKK', // Danish Krone
    ch: 'CHF', // Swiss Franc
  };

  const currencyCode = currencyMap[countryCode];

  // Create a regular expression to match the currency symbol
  const currencySymbols: any = {
    USD: '\\$',
    GBP: '£',
    EUR: '€',
    CAD: 'CA\\$',
    AUD: 'A\\$',
    NZD: 'NZ\\$',
    JPY: '¥',
    CNY: 'CN¥',
    VND: '₫',
    THB: '฿',
    MYR: 'RM',
    SGD: 'S\\$',
    PHP: '₱',
    IDR: 'Rp',
    KRW: '₩',
    INR: '₹',
    BRL: 'R\\$',
    MXN: 'MX\\$',
    ZAR: 'R',
    RUB: '₽',
    TRY: '₺',
    SAR: 'SAR',
    AED: 'AED',
    SEK: 'kr',
    NOK: 'kr',
    DKK: 'kr',
    CHF: 'CHF'
  };

  const currencySymbolRegex = new RegExp(`^(${currencySymbols[currencyCode] || '\\$'})`);

  // Remove the currency symbol and commas, then convert to number
  const plainNumber = Number(formattedPrice?.replace(currencySymbolRegex, '').replace(/,/g, ''));

  return plainNumber;
};

const hasTwoOrMorePrices = (priceString: any): boolean => {
  if(
    priceString
  ){
    const pricePattern = /(?:[^\d\s,]*)(\d{1,3}(?:,\d{3})*(?:[\.,]\d+)?)(?:[^\d\s,]*)/g;
    const matches = [...priceString?.matchAll(pricePattern)];
    return matches.length >= 2;
  }

  return false


};

export const extractFirstPrice = (priceString: any) => {
  if(hasTwoOrMorePrices(priceString)){
    const pricePattern = /([^\d\s,]+)?(\d+(?:[\.,]\d+)?)([^\d\s,]+)?/g;
    const matches = [...priceString?.matchAll(pricePattern)];

    if (matches.length > 0) {
      for (const match of matches) {
        const [fullMatch, prefix, number, suffix] = match;
        if (prefix || suffix) {
          return `${prefix || ''}${number}${suffix || ''}`;
        }
      }
    }
  }

  return priceString;
};

export const createArrayNumber = (n: number) => {
  return Array(n).fill(0).map((_, i) => i + 1);
}

export const extractNumber = (input: any) => {
  if(!input){
    return 0
  }
  // Remove any non-numeric characters except for the decimal point
  let numberString = input.replace(/[^\d.,]/g, '');

  const splitNumber = numberString.split(',');
  if(splitNumber.length > 1){
    if(splitNumber.at(-1).length > 2){
      numberString = splitNumber.join('.')
    }
  }else{
    // Replace comma with dot for decimal point consistency
    numberString = numberString.replace(',', '.');
  }

  // Ensure only one decimal point exists
  const parts = numberString.split('.');
  if (parts.length > 2) {
    // Join all parts except the last one and add the last part with a single decimal point
    numberString = parts.slice(0, -1).join('') + '.' + parts.slice(-1);
  }

  return +numberString;
}