export default {
  titleSignIn: 'Sign in to Shopper',
  email: 'Email',
  password: 'Password',
  titleChangePassword: 'Change your password',
  newPass: 'New password',
  confirmPass: 'Confirm password',
  errorPassword:
    'Password: 8+ characters, with a mix of uppercase, lowercase, numbers, and symbols.',
  errorConfirmPass: 'Passwords do not match.',
  placeholderNewPass: 'Please enter your new password',
  placeholderConfirmPass: 'Please confirm your password',
  proceed: 'Proceed',
  passwordRequired: 'Password is required.',
  emailRequired: 'Please input your email!',
  invalidEmail: 'Email invalid.'
};
