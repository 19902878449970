import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PayloadListProductRakuten } from '../../types/api';
const initialState: any = {
  products: {}
};

export const rakutenSlice = createSlice({
  name: "rakuten",
  initialState,
  reducers: {
    setProductsRakuten(state, action: PayloadAction<PayloadListProductRakuten>) {
      state.products = action.payload;
    },
  },
});

export const {
  setProductsRakuten,
} = rakutenSlice.actions;

export default rakutenSlice.reducer;